import Bar from '../../components/Reports/Bar'
import DoughNut from '../../components/Reports/DoughNut'
import LineStack from '../../components/Reports/LineStack'
import Heat from '../../components/Reports/Heat'
import DottedLine from '../../components/Reports/DottedLine'
import Tree from '../../components/Reports/Tree'
import Overlap from '../../components/Reports/Overlap'
import ActionButtons from '../../components/ActionButtons/ActionButtons'
import ContactsIcon from '@mui/icons-material/Contacts';
import { API } from '../../lib/api'
import * as XLSX from 'xlsx';
import UpdateIcon from '@mui/icons-material/Update';
import Feedback from '../../components/Feedback/Feedback'
import { useEffect, useState } from 'react'
import Papa from 'papaparse';
import OnlinePredictionIcon from '@mui/icons-material/OnlinePrediction';
import Module from './Module'
import ResponsiveColumnsTSTM from './ResponsiveColumnsTSTM'
import DataTable from "../../components/DataTable/DataTable"
import { Box, Card, CardContent, CardHeader, CircularProgress, IconButton, Tooltip } from '@mui/material'
import { DataGridPro } from '@mui/x-data-grid-pro'
import { DriveFileMove } from '@mui/icons-material'

const Content = () => {
    const [loading, setLoading] = useState(true);
    const [feedback, setFeedback] = useState({open: false, message: "", type: ""})
    const [oppsByOwner, setOppsByOwner] = useState({ authorized: false})
    const [revLinesTSTM, setRevLinesTSTM] = useState([]) 

    const tstmColumns = ResponsiveColumnsTSTM()

    const handleCustomerMaster = async () => {
        const response = await API.Reporting.FetchCustomerMaster(localStorage.token)

        if (response.status === 200) {
            const today = new Date();
            const formattedDate = `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`;
            const worksheet = XLSX.utils.json_to_sheet(response.data);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');
            XLSX.writeFile(workbook, `Customer_Master_${formattedDate}.xlsx`);
        } else {
            setFeedback(prevData => ({ ...prevData, open: true, message: "You do not have permission to access this resource!", type: 'error' }))
        }
    };

    const handleForecastFile = async () => {
        const response = await API.Reporting.FetchForecastFile(localStorage.token);
    
        if (response.status === 200) {
            const today = new Date();
            const formattedDate = `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`;
    
            // Convert JSON to CSV using PapaParse
            const csv = Papa.unparse(response.data, {
                quotes: true, // Ensures values are properly quoted
                quoteChar: '"',
                escapeChar: '"',
                delimiter: ",",
                header: true, // Ensures headers are included
            });
    
            // Create a Blob and trigger download
            const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = `The_Hive_Forecast_${formattedDate}.csv`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            setFeedback(prevData => ({
                ...prevData,
                open: true,
                message: "You do not have permission to access this resource!",
                type: 'error',
            }));
        }
    };

    const modules = [
        { title: "Forecast File Download", description: "Download Forecast File", onClick: handleForecastFile, icon: <UpdateIcon color='primary' sx={{fontSize: 50}} /> },
        { title: "Customer Master Download", description: "Download Customer Master", onClick: handleCustomerMaster, icon: <ContactsIcon color='primary' sx={{fontSize: 50}} /> },
        // { title: "Forecast Module 2", description: "Open Module", icon: <OnlinePredictionIcon color='primary' sx={{fontSize: 50}} /> },
        // { title: "Forecast Module 3", description: "Open Module", icon: <OnlinePredictionIcon color='primary' sx={{fontSize: 50}} /> },
        // { title: "Forecast Module 4", description: "Open Module", icon: <OnlinePredictionIcon color='primary' sx={{fontSize: 50}} /> },
        // { title: "Forecast Module 5", description: "Open Module", icon: <OnlinePredictionIcon color='primary' sx={{fontSize: 50}} /> }
    ]

    useEffect(() => {
        async function FetchData() {
            const response = await API.Reporting.FetchOppsByUser()
            const responseRevLinesTSTM = await API.Reporting.RevLinesTSTM()
            if (response.status === 200) {
                setOppsByOwner(response.data)
            }
            if (responseRevLinesTSTM.status === 200) {
                setRevLinesTSTM(responseRevLinesTSTM.data)
            }
            setLoading(false)
        }
        FetchData()
    }, [])

    return (
        <>
            {loading ? (
                <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
                    <CircularProgress />
                </Box>
            ) : (
                <>
            <Feedback props={feedback} setFeedback={setFeedback} />
            <div className='row' style={{justifyContent: "center"}}>
                {modules.map(mod => (
                    <Module key={mod.title} title={mod.title} icon={mod.icon} description={mod.description} onClick={mod.onClick} />
                ))}
                {oppsByOwner.authorized && 
                    <Bar xData={oppsByOwner.xData} seriesData={oppsByOwner.seriesData} title={oppsByOwner.title} isModule={true} />
                }

                <div className="col-xl-12 col-md-12" style={{marginTop: "1em", marginBottom: "1em"}}>
                    <Card>
                        <CardHeader title="Revenue Lines To Ship This Month" />
                        <CardContent>
                            <DataGridPro
                            sx={{backgroundColor: "white"}}
                                checkboxSelection
                                rows={revLinesTSTM}
                                columns={tstmColumns}
                                getRowId={(row) => row._id}
                                pagination
                                initialState={{
                                    pagination: {
                                        paginationModel: { pageSize: 10, page: 0 },
                                    },
                                }}
                                pageSizeOptions={[10, 25, 50]}
                            />
                            <div style={{display: "flex", justifyContent: "center"}}>
                                <Tooltip title={"Add Module To Dashboard - Currently Unavailable"}>
                                    <IconButton>
                                        <DriveFileMove color={"secondary"} />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </CardContent>
                    </Card>
                </div>
                {/* <DoughNut/> */}
                {/* <LineStack/> */}
                {/* <Heat/> */}
                {/* <DottedLine/> */}
                {/* <Tree/> */}
                {/* <Overlap/> */}
            </div>
            </>
            )}        
        </>
    )
}

export default Content