import { useMediaQuery } from '@mui/material';

const ResponsiveColumnsTSTM = () => {
    // Define the breakpoints
    const isMobile = useMediaQuery('(max-width:600px)');

    return [
        {
            field: 'oppName',
            headerName: 'Opportunity Name',
            flex: 1,
            renderCell: ({row: { oppId, oppName } }) => <a href={`/opportunities/${oppId}`}>{oppName}</a>
        },
        // Conditionally show/hide columns
        ...(isMobile ? [] : [
            {
                field: "lineComments",
                headerName: "Line Comments",
                flex: 1,
            },
            {
                field: "amount",
                headerName: "Amount",
                flex: 1,
                renderCell: ({ value }) => {
                    if (value == null) return ""; // Handle null/undefined values
                    return new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                    }).format(value);
                },
            },
            {
                field: "productGroup",
                headerName: "Product Group",
                flex: 1,
            },
            {
                field: "shipDate",
                headerName: "Ship Date",
                flex: 1,
                renderCell: ({ row: { shipDate } }) => shipDate ? shipDate.split("T")[0] : "",
            },
            {
                field: "bookedOrdersStage",
                headerName: "Booked Orders Stage",
                flex: 1,
            },
            // {
            //     field: "customer",
            //     headerName: "Customer",
            //     flex: 1,
            //     renderCell: ({row: { customer} }) => customer?.organizationName,
            //     valueGetter: (params) => params?.organizationName 
            // },
            // {
            //     field: "primaryContact",
            //     headerName: "Primary Contact",
            //     flex: 1,
            //     renderCell: ({row: { primaryContact} }) => primaryContact?.name,
            //     valueGetter: (params) => params?.name 
            // },
            // {
            //     field: "owner",
            //     headerName: "Owner",
            //     flex: 1,
            //     renderCell: ({ row: { owner } }) => owner ? `${owner.firstName} ${owner.lastName}` : 'Unassigned',
            //     valueGetter: (params) => `${params.firstName} ${params.lastName}` 
            // },
            // {
            //     field: "amount",
            //     headerName: "Amount",
            //     flex: 1,
            //     renderCell: ({ row: { amount }}) => amount ? new Intl.NumberFormat("en-US", {
            //         style: "currency",
            //         currency: "USD",
            //     }).format(amount) :'$0.00'
            // },
            // {
            //     field: "closeDate",
            //     headerName: "Close Date",
            //     renderCell: ({ row: { closeDate } }) => closeDate ? closeDate.split("T")[0] : "",
            //     flex: 1
            // }
        ]),
        // {
        //     field: "salesStage",
        //     headerName: "Stage",
        //     flex: 1,
        //     renderCell: ({ row: { salesStage } }) => {
        //         return (
        //             <label className={`badge  ${salesStage === '01 - Lead' ? 'bg-light-primary' : salesStage === '08 - Won' ? 'bg-light-success' :salesStage === '05 - Committee Approval' ? 'bg-light-warning' : salesStage === '04 - Internal Revision' ? 'bg-light-danger' : salesStage === '06 - Quote Sent' ? 'bg-light-primary' : salesStage === '02 - Identified' ? 'bg-light-dark' : salesStage === '03 - Committee' ? 'bg-light-secondary' : salesStage === "07 - External Revision" ? 'bg-light-danger' : 'bg-light-info'}`} style={{minHeight: '75%', minWidth: '75%', padding: "0", lineHeight: '3', fontSize: '14px'}}>{salesStage}</label>
        //         );
        //     },
        // },
    ];
};

export default ResponsiveColumnsTSTM